<template>
  <b-overlay
      :show="AllTickets === null"
      rounded="sm"
  >
    <div v-if="AllTickets !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0">
        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="AllTickets"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found !"
        >

          <!-- Column: delete -->

          <template #cell(userInfo)="data">
            <p>{{data.item.userInfo.userName}}</p>
          </template>


          <template #cell(type)="data">
            <span class="badge pill text-white bg-primary" v-if="data.item.type===0">Public</span>
            <span class="badge pill text-white bg-primary" v-if="data.item.type===1">Authenticate</span>
            <span class="badge pill text-white bg-primary" v-if="data.item.type===2">Support</span>
            <span class="badge pill text-white bg-primary" v-if="data.item.type===3">Other</span>
          </template>
          <template #cell(status)="data">

            <b-badge v-if=" data.item.isRead === false" variant="secondary">
              Not seen
            </b-badge>
            <b-badge v-else-if=" data.item.isRead === true" variant="success">
              Seen
            </b-badge>

          </template>
          <template #cell(Detail)="data">
            <b-link class="cursor-pointer" :to="`/apps/ticketDetail/${data.item.ticketId}`">
              <feather-icon icon="EyeIcon" size="20" class="text-primary" />
            </b-link>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup,BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {TicketGetAllRequest} from "@/libs/Api/Ticket";



import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  title: 'Tickets List',
  name: 'AllTickets',
  data() {
    return {
      AllTickets: null,
      totalCount: null,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      CategoryName:'',
      TicketTypesId:[

        {
          option:"احراز هویت",
          value:1
        },
        {
          option:"پشتیبانی",
          value:2
        },
        {
          option:"سایر",
          value:3
        },
      ],
      TicketType:0,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'title',
          label: 'title'
        },
        {
          key: 'userInfo',
          label: 'Sender'
        },
        {
          key: 'type',
          label: 'type'
        },
        {
          key: 'status',
          label: 'status'
        },
        {
          key: 'Detail',
          label: 'show'
        },
      ],

      pageNumber: 1,
      count: 10,
      search: '',
      SelectedTicket: null,
    }
  },
  async created() {
    await this.GetAllTicket()
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {
    search: function () {
      this.GetAllTicket()
    },
    currentPage: function () {
      this.GetAllTicket()
    },
    TicketType:function (){
      this.GetAllTicket()
    }

  },
  methods: {

    async GetAllTicket() {
      let _this = this
      let ticketGetAllRequest = new TicketGetAllRequest(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.count,
      }
      ticketGetAllRequest.setParams(data)
      await ticketGetAllRequest.fetch(function (content) {
        _this.AllTickets = content.tickets

        _this.totalCount = content.ticketCount
      }, function (error) {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
